<template>
    <Container title="Billing" :current-name="$options.name">
        <h1>{{$options.name}}</h1>
    </Container>

</template>

<script>
import Container from '../../components/Container'
export default {
    name:'Billing',
    components:{
        Container
    }
}
</script>